import type { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import Link from 'next/link';

import styles from '../styles/404.module.scss';

import { NextPageWithLayout } from './_app';

const PageTitle = 'Not found';

const Page: NextPageWithLayout = () => {
  return (
    <div>
      <Head>
        <title>{PageTitle}</title>
      </Head>

      <div className={styles.backgroundContent}>
        {/* 404 */}
        <svg width="1093" height="450" viewBox="0 0 1093 450" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M134.855 7.59201H241.118V271.242H304.6V342.331H241.118V442.408H153.485V342.331H-48V283.666L134.855 7.59201ZM116.224 165.644L45.8426 271.242H153.485V173.236L157.626 89.7239H156.246L116.224 165.644Z"
            fill="#24292D"
          />
          <path
            d="M523.842 0C549.603 0 573.294 4.37116 594.914 13.1135C616.995 21.3957 635.855 34.7393 651.496 53.1442C667.596 71.089 680.017 94.3251 688.757 122.853C697.957 150.92 702.557 184.739 702.557 224.31C702.557 263.42 697.727 297.239 688.067 325.767C678.407 353.834 665.296 377.071 648.736 395.475C632.175 413.88 613.085 427.684 591.464 436.886C569.844 445.629 547.073 450 523.152 450C497.392 450 473.471 445.859 451.39 437.577C429.77 428.834 410.909 415.261 394.809 396.856C378.708 378.451 366.058 354.985 356.858 326.457C348.118 297.929 343.747 263.88 343.747 224.31C343.747 186.58 348.578 153.681 358.238 125.614C368.358 97.0859 381.698 73.6196 398.259 55.2147C414.819 36.8098 433.91 23.0061 455.53 13.8037C477.611 4.60122 500.382 0 523.842 0ZM438.28 224.31C438.28 255.138 440.58 280.675 445.18 300.92C449.78 321.166 455.99 337.27 463.811 349.233C472.091 361.196 481.291 369.709 491.411 374.77C501.992 379.371 512.802 381.672 523.842 381.672C534.883 381.672 545.463 379.141 555.583 374.08C566.163 369.018 575.134 360.506 582.494 348.543C590.314 336.58 596.524 320.475 601.124 300.23C605.725 279.985 608.025 254.678 608.025 224.31C608.025 193.482 605.725 168.175 601.124 148.39C596.524 128.144 590.314 112.27 582.494 100.767C575.134 88.8037 566.163 80.5215 555.583 75.9202C545.463 70.8589 534.883 68.3282 523.842 68.3282C512.802 68.3282 501.992 70.8589 491.411 75.9202C481.291 80.5215 472.091 88.8037 463.811 100.767C455.99 112.73 449.78 128.834 445.18 149.08C440.58 168.865 438.28 193.942 438.28 224.31Z"
            fill="#24292D"
          />
          <path
            d="M923.255 7.59201H1029.52V271.242H1093V342.331H1029.52V442.408H941.886V342.331H740.4V283.666L923.255 7.59201ZM904.625 165.644L834.243 271.242H941.886V173.236L946.026 89.7239H944.646L904.625 165.644Z"
            fill="#24292D"
          />
        </svg>
        {/* Not found */}
        <svg width="1440" height="267" viewBox="0 0 1440 277" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M-117 31.2603H-70.9889L8.88644 153.36C14.2851 161.696 19.1929 169.909 23.61 178C28.2725 185.846 31.5853 191.975 33.5484 196.388H34.2846C32.8122 180.452 32.0761 164.638 32.0761 148.946C32.0761 133.01 32.0761 116.95 32.0761 100.769V31.2603H78.8234V262.955H32.8122L-41.5417 150.417C-44.7319 145.514 -47.922 140.488 -51.1121 135.339C-54.0568 129.945 -56.8788 124.796 -59.5781 119.893C-62.2774 114.989 -64.7314 110.576 -66.9399 106.653C-68.903 102.485 -70.3754 99.0523 -71.357 96.3554H-72.0931C-70.8662 113.273 -70.2527 129.577 -70.2527 145.269C-70.0073 160.715 -69.8846 176.529 -69.8846 192.711V262.955H-117V31.2603Z"
            fill="#24292D"
          />
          <path
            d="M297.071 173.955C297.071 188.42 294.74 201.415 290.078 212.938C285.415 224.461 278.912 234.269 270.569 242.359C262.471 250.205 252.901 256.335 241.858 260.748C230.815 264.916 218.914 267 206.153 267C193.393 267 181.491 265.039 170.449 261.116C159.651 257.193 150.204 251.431 142.106 243.831C134.253 236.23 127.996 226.913 123.333 215.88C118.916 204.602 116.708 191.853 116.708 177.632C116.708 162.676 119.039 149.437 123.701 137.913C128.364 126.145 134.744 116.337 142.842 108.492C151.185 100.401 160.878 94.2713 171.921 90.1033C182.964 85.9353 194.865 83.8512 207.626 83.8512C220.386 83.8512 232.165 85.8127 242.962 89.7355C254.005 93.6584 263.453 99.4201 271.305 107.021C279.403 114.376 285.661 123.693 290.078 134.971C294.74 146.249 297.071 159.244 297.071 173.955ZM246.643 175.793C246.643 165.741 245.539 157.16 243.33 150.05C241.122 142.939 238.177 137.178 234.496 132.764C230.815 128.351 226.521 125.164 221.613 123.202C216.705 120.996 211.675 119.893 206.521 119.893C201.368 119.893 196.46 120.873 191.798 122.835C187.135 124.551 182.964 127.616 179.283 132.029C175.602 136.197 172.657 141.836 170.449 148.946C168.24 156.056 167.136 165.006 167.136 175.793C167.136 185.355 168.24 193.691 170.449 200.802C172.657 207.667 175.602 213.306 179.283 217.719C183.209 222.132 187.504 225.442 192.166 227.649C197.074 229.855 202.104 230.959 207.258 230.959C212.411 230.959 217.319 230.101 221.981 228.384C226.644 226.423 230.815 223.236 234.496 218.822C238.177 214.409 241.122 208.77 243.33 201.905C245.539 194.795 246.643 186.091 246.643 175.793Z"
            fill="#24292D"
          />
          <path
            d="M436.796 122.835H390.048V203.744C390.048 212.08 391.889 217.842 395.57 221.029C399.496 223.971 404.649 225.442 411.029 225.442C415.937 225.442 420.968 224.952 426.121 223.971C431.274 222.99 435.446 221.642 438.636 219.926L445.998 254.128C441.826 256.09 435.569 258.051 427.225 260.012C418.882 261.974 410.048 262.955 400.723 262.955C379.374 262.955 364.159 258.786 355.08 250.45C346 241.869 341.461 229.12 341.461 212.202V122.835H314.222V87.5289H341.461V33.4669H390.048V87.5289H436.796V122.835Z"
            fill="#24292D"
          />
          <path
            d="M629.05 0C637.884 0 646.596 1.22589 655.185 3.67768C663.773 5.88429 671.258 8.82644 677.638 12.5041L668.068 44.5C663.896 42.5386 659.724 40.9449 655.553 39.719C651.381 38.2479 646.841 37.5124 641.933 37.5124C633.345 37.5124 626.964 40.0868 622.793 45.2355C618.621 50.1391 616.535 58.5978 616.535 70.6116V87.5289H660.706V122.835H616.535V262.955H567.948V122.835H541.077V87.5289H567.948V60.3141C567.948 40.6997 573.469 25.7438 584.512 15.4463C595.554 5.14876 610.401 0 629.05 0Z"
            fill="#24292D"
          />
          <path
            d="M856.754 173.955C856.754 188.42 854.422 201.415 849.76 212.938C845.098 224.461 838.595 234.269 830.251 242.359C822.153 250.205 812.583 256.335 801.54 260.748C790.498 264.916 778.596 267 765.836 267C753.075 267 741.174 265.039 730.131 261.116C719.334 257.193 709.886 251.431 701.788 243.831C693.936 236.23 687.678 226.913 683.016 215.88C678.599 204.602 676.39 191.853 676.39 177.632C676.39 162.676 678.721 149.437 683.384 137.913C688.046 126.145 694.426 116.337 702.524 108.492C710.868 100.401 720.561 94.2713 731.603 90.1033C742.646 85.9353 754.548 83.8512 767.308 83.8512C780.068 83.8512 791.847 85.8127 802.645 89.7355C813.687 93.6584 823.135 99.4201 830.987 107.021C839.085 114.376 845.343 123.693 849.76 134.971C854.422 146.249 856.754 159.244 856.754 173.955ZM806.325 175.793C806.325 165.741 805.221 157.16 803.013 150.05C800.804 142.939 797.859 137.178 794.179 132.764C790.498 128.351 786.203 125.164 781.295 123.202C776.388 120.996 771.357 119.893 766.204 119.893C761.051 119.893 756.143 120.873 751.48 122.835C746.818 124.551 742.646 127.616 738.965 132.029C735.284 136.197 732.34 141.836 730.131 148.946C727.922 156.056 726.818 165.006 726.818 175.793C726.818 185.355 727.922 193.691 730.131 200.802C732.34 207.667 735.284 213.306 738.965 217.719C742.891 222.132 747.186 225.442 751.848 227.649C756.756 229.855 761.787 230.959 766.94 230.959C772.093 230.959 777.001 230.101 781.663 228.384C786.326 226.423 790.498 223.236 794.179 218.822C797.859 214.409 800.804 208.77 803.013 201.905C805.221 194.795 806.325 186.091 806.325 175.793Z"
            fill="#24292D"
          />
          <path
            d="M1004.94 87.5289H1053.9V262.955H1017.46L1012.31 243.095H1010.83C1008.13 245.792 1004.94 248.612 1001.26 251.554C997.828 254.251 993.656 256.825 988.748 259.277C984.086 261.483 978.687 263.322 972.552 264.793C966.663 266.264 960.037 267 952.675 267C933.78 267 918.811 261.729 907.768 251.186C896.971 240.643 891.573 225.074 891.573 204.479V87.5289H940.528V200.434C940.528 210.977 942.982 218.577 947.89 223.236C953.043 227.894 959.546 230.223 967.399 230.223C976.233 230.223 983.718 228.384 989.852 224.707C996.233 221.029 1001.26 216.738 1004.94 211.835V87.5289Z"
            fill="#24292D"
          />
          <path
            d="M1146.86 262.955H1097.9V87.5289H1136.18L1142.07 109.963C1150.17 101.627 1159.01 95.2521 1168.58 90.8388C1178.39 86.1804 1189.19 83.8512 1200.97 83.8512C1220.35 83.8512 1235.2 89.1226 1245.51 99.6653C1255.81 110.208 1260.97 125.532 1260.97 145.636V262.955H1212.01V150.785C1212.01 141.713 1209.8 134.603 1205.39 129.455C1200.97 124.061 1194.47 121.364 1185.88 121.364C1179.5 121.364 1172.75 123.202 1165.63 126.88C1158.76 130.313 1152.5 135.094 1146.86 141.223V262.955Z"
            fill="#24292D"
          />
          <path
            d="M1419.04 0.367764H1468V262.955H1433.03L1427.88 243.831H1426.04C1421.62 249.96 1415.24 255.354 1406.9 260.012C1398.8 264.671 1388.12 267 1374.87 267C1363.83 267 1353.52 264.916 1343.95 260.748C1334.38 256.58 1326.04 250.573 1318.92 242.727C1311.81 234.882 1306.16 225.32 1301.99 214.041C1298.07 202.763 1296.1 189.891 1296.1 175.426C1296.1 161.45 1297.94 148.824 1301.62 137.545C1305.3 126.267 1310.58 116.705 1317.45 108.859C1324.32 100.769 1332.79 94.6391 1342.85 90.4711C1352.91 86.0579 1364.44 83.8512 1377.45 83.8512C1385.79 83.8512 1393.65 85.0771 1401.01 87.5289C1408.61 89.9807 1414.63 93.168 1419.04 97.0909V0.367764ZM1419.04 133.5C1415.85 129.577 1411.68 126.267 1406.53 123.57C1401.38 120.873 1395.73 119.525 1389.6 119.525C1383.71 119.525 1378.06 120.506 1372.67 122.467C1367.51 124.428 1362.85 127.616 1358.68 132.029C1354.75 136.197 1351.56 141.836 1349.11 148.946C1346.65 155.811 1345.43 164.27 1345.43 174.322C1345.43 184.129 1346.53 192.588 1348.74 199.698C1350.95 206.809 1353.89 212.693 1357.57 217.351C1361.25 222.01 1365.55 225.442 1370.46 227.649C1375.61 229.855 1381.01 230.959 1386.65 230.959C1394.51 230.959 1401.13 229.12 1406.53 225.442C1412.17 221.519 1416.34 216.983 1419.04 211.835V133.5Z"
            fill="#24292D"
          />
        </svg>
      </div>
      <div className={styles.interactiveContent}>
        <h1>404</h1>
        <h2>Not Found</h2>
        <p>There is nothing here. Try to find it on the main page.</p>
        <Link href="/">
          <a>Home</a>
        </Link>
      </div>
    </div>
  );
};

Page.authRequired = false;

Page.getLayout = (page) => page;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
    },
  };
};

export default Page;
